import React from 'react'

const LanguageSelector = ({
  setLanguage, language, languages, children,
}: { setLanguage: (v: string) => void, language?: string, languages?: {[key: string]: string}, children: React.ReactNode }) => (
  <div>
    <div className="flex">
      {languages && Object.keys(languages).map(key => (
        <div
          key={key}
          className={`text-xs rounded-t pt-1 px-4 cursor-pointer ${key === language ? 'bg-grayLight wght-semibold' : ''
            }`}
          onClick={() => setLanguage(key)}
        >
          {languages[key]}
        </div>
      ))}
    </div>
    {children}
  </div>
)

export default LanguageSelector
