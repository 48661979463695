import React, { useRef } from 'react'
import colorFromString from '../../lib/colorFromString'
import Form from './Form'
import {PttModelField, SelectOptionType, UpdateStoredModels, PttModelType} from '../../types'
import type { Identifier, XYCoord } from 'dnd-core'
import { useDrag, useDrop } from 'react-dnd'

type Props = {
  models: {[key: string]: PttModelField[]}
  languages: {[key: string]: string}
  find?: {[key: string]: SelectOptionType[]}
  sorting: boolean 
  showErrors: boolean 
  index: number 
  updateStored: UpdateStoredModels
  modelsAvailable: { [key: string]: string }, 
  deleteModel: (index: number) => void
  item: PttModelType, 
  moveModel: (dragIndex: number, hoverIndex: number) => void,
}

interface DragItem {
  index: number
  id: string
  type: string
}

const ModelsRow = ({ 
  item, 
  deleteModel, 
  modelsAvailable, 
  updateStored, 
  models,
  index, 
  sorting, 
  showErrors,
  find,
  languages, 
  moveModel,
}: Props) => {

  const ref = useRef<HTMLDivElement>(null)

  const [, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'CARD',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      moveModel(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

    const [, drag] = useDrag({
      type: 'CARD',
      item: () => {
        return { id: item.key, index }
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => sorting,
    })

    drag(drop(ref))

  return (
    <div 
      ref={ref}
      className="bg-white rounded">
      <div
        className={`${sorting ? 'cursor-move' : ''} relative mt-2 rounded`}
        style={{
          backgroundColor: colorFromString(item.modelName, 0.4),
        }}
      >
        <div
          className={`flex items-center justify-between rounded-t ${sorting ? 'px-1 py-1 text-xs' : 'p-4'
            }`}
          style={{
            backgroundColor: colorFromString(item.modelName, 0.4),
          }}
        >
          <span className="wght-semibold">
            {modelsAvailable[item.modelName]}
          </span>
          {!sorting && <span
            className="text-xs cursor-pointer hover:wght-semibold"
            onClick={() => deleteModel(index)}
          >
            Delete
          </span>}
        </div>
        {models[item.modelName]?.length > 0 &&
          <div
            className={`p-2 ${sorting ? 'md:py-1 md:px-1 text-xs h-30 overflow-scroll pointer-events-none' : 'md:pt-4 md:px-2'
              }`}
          >
            <Form
              innerForm={true}
              parentIsSorting={sorting}
              key={item.key}
              onChange={(n, v, errs) => updateStored(index, item.modelName, n, v, errs || [])}
              showErrors={showErrors}
              name={item.modelName}
              data={{ models, stored: item, languages, find }}
            />
          </div>}
      </div>
    </div>
  )
}

export default ModelsRow