import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'

interface PasswordProps extends PttFieldType {
  mode?: string
  hideRepeat?: boolean
  onKeyPress?: (e: React.KeyboardEvent) => void
}

const Password = ({
  mode,
  storedData,
  showErrors,
  onChange,
  name,
  options: { label },
  validations,
  hideRepeat = false,
  onKeyPress,
  innerForm,
  defaultLanguage,
}: PasswordProps) => {


  const defaultValue = getDefaultValue({ name, storedData, translatable: true, })
  const [language] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)

  const [errors, setErrors] = useState<string[]>([])
  const [currentRepeatedValue, setCurrentRepeatedValue] = useState('')

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = e.target.value
    setValue({ ...value })
  }

  const onChangeRepeatedText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentRepeatedValue(e.target.value)
  }

  useEffect(() => {
    const errs = Errors(value, validations, false, {}, {
      repeated: currentRepeatedValue,
      mode,
    })
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, currentRepeatedValue, mode, name, onChange, validations])

  const input = (
    <input
      name={name}
      className={`w-full rounded px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
      type="password"
      placeholder={label}
      value={`${value[language] || ''}`}
      onChange={onChangeText}
      onKeyPress={onKeyPress}
    />
  )

  const inputRepeated = (
    <input
      name={name}
      className={`w-full rounded px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
      type="password"
      placeholder={label}
      value={currentRepeatedValue}
      onChange={onChangeRepeatedText}
    />
  )

  return (
    <div className="md:flex">
      <div className="flex-1 mb-8 md:mb-0">
        <label className="wght-semibold">{label}</label>
        {input}
        {showErrors && errors.length > 0 &&
          <p className="text-error">{errors[0]}</p>
        }
      </div>
      {hideRepeat === true || (
        <div className="flex-1 md:ml-4">
          <label>{label} Repeat</label>
          {inputRepeated}
        </div>
      )}
    </div>
  )
}

export default Password
