export const mapProductToRow = (product: any): any[] => [
  product.FoId,
  product.Title,
  product.Description?.['es-ES'] ?? '',
  product.Description?.['fr-FR'] ?? '',
  product.Description?.['en-US'] ?? '',
  product.Description?.['en-UK'] ?? '',
  product.Image,
  product['Ficha Técnica']?.['es-ES'] ?? '',
  product['Ficha Técnica']?.['fr-FR'] ?? '',
  product['Ficha Técnica']?.['en-US'] ?? '',
  product['Ficha Técnica']?.['en-UK'] ?? '',
  product['Manual de Instrucciones de Montaje']?.['es-ES'] ?? '',
  product['Manual de Instrucciones de Montaje']?.['fr-FR'] ?? '',
  product['Manual de Instrucciones de Montaje']?.['en-US'] ?? '',
  product['Manual de Instrucciones de Montaje']?.['en-UK'] ?? '',
  product['Archivo CAD']?.['es-ES'] ?? '',
  product['Archivo CAD']?.['fr-FR'] ?? '',
  product['Archivo CAD']?.['en-US'] ?? '',
  product['Archivo CAD']?.['en-UK'] ?? '',
  product['Archivo BIM']?.['es-ES'] ?? '',
  product['Archivo BIM']?.['fr-FR'] ?? '',
  product['Archivo BIM']?.['en-US'] ?? '',
  product['Archivo BIM']?.['en-UK'] ?? '',
  product['Product Family'] ?? '',
  product['Product Model'] ?? '',
  product['Product Variants'] ?? '',
  product.Attributes?.['Número de hojas'] ?? '',
  product.Attributes?.['Aplicación'] ?? '',
  product.Attributes?.['Material de la puerta'] ?? '',
  product.Attributes?.['Cerramientos'] ?? '',
  product.Attributes?.['Compatibilidad de falso techo'] ?? '',
  product.Attributes?.['Peso'] ?? '',
  product.Attributes?.['Grosor del cristal'] ?? '',
  product.Attributes?.['Sistema de frenado'] ?? '',
  product.Attributes?.['Visibilidad del herraje'] ?? '',
  product.Attributes?.['Acabado'] ?? '',
  product.Attributes?.['Longitud del perfil'] ?? '',
  product.Attributes?.['Tipo de amortiguación'] ?? '',
  product.Attributes?.['Tipo de tirador'] ?? '',
  product.Attributes?.['Número de bisagras'] ?? '',
  product.Attributes?.['Tipo de perfil'] ?? '',
  product.Attributes?.['Puertas enfrentadas'] ?? '',
  product.Attributes?.['Tipo de plegado'] ?? '',
  product.Attributes?.['Puerta con marco'] ?? '',
  product.Attributes?.['Cerradero'] ?? '',
  product.Attributes?.['Sentido apertura'] ?? '',
  product.Attributes?.['Tipo'] ?? '',
  product.Attributes?.['Perfiles en corner'] ?? '',
  product.Attributes?.['Vidrio Fijo'] ?? '',
  product.Attributes?.['Longitud Tirador'] ?? '',
  // product.Attributes?.['Tipos de apertura'] ?? '',
  // product.Attributes?.['Peso de la puerta'] ?? '',
  // product.Attributes?.['Acabado del tirador'] ?? '',
  // product.Attributes?.['Acabado del Sistema'] ?? '',
  // product.Attributes?.['Material del panel móvil'] ?? '',
  // product.Attributes?.['ONLINE Venta'] ?? '',
  // product.Attributes?.['Fijación del perfil'] ?? '',
  // product.Attributes?.['Alineación de puertas'] ?? '',
  // product.Attributes?.['Tipo de puerta'] ?? '',
  // product.Attributes?.['Sistema de apertura'] ?? '',
  // product.Attributes?.['Posición respecto al muro'] ?? '',
  product['Related Products'] ?? '',
  product['Venta Directa'] ?? '',
  product['Venta Distribuidor'] ?? '',
]
