export const PRODUCT_HEADER_ROW_1 = [
  'FoId',
  'Title',
  'Description',
  null,
  null,
  null,
  'Image',
  'Ficha Técnica',
  null,
  null,
  null,
  'Manual de instrucciones de montaje',
  null,
  null,
  null,
  'Archivo CAD',
  null,
  null,
  null,
  'Archivo BIM',
  null,
  null,
  null,
  'Product Family',
  'Product Model',
  'Product Variants',
  'Attributes',
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  null,
  'Related Products',
  'Venta Directa',
  'Venta Distribuidor',
]

export const PRODUCT_HEADER_ROW_2 = [
  null,
  null,
  'es-ES',
  'fr-FR',
  'en-US',
  'en-UK',
  null,
  'es-ES',
  'fr-FR',
  'en-US',
  'en-UK',
  'es-ES',
  'fr-FR',
  'en-US',
  'en-UK',
  'es-ES',
  'fr-FR',
  'en-US',
  'en-UK',
  'es-ES',
  'fr-FR',
  'en-US',
  'en-UK',
  null,
  null,
  null,
  'Aplicación',
  'Material de la puerta',
  'Cerramientos',
  'Compatibilidad de falso techo',
  'Peso',
  'Grosor del cristal',
  'Sistema de frenado',
  'Visibilidad del herraje',
  'Número de hojas',
  'Acabado',
  'Longitud del perfil',
  'Tipo de amortiguación',
  'Tipo de tirador',
  'Número de bisagras',
  'Tipo de perfil',
  'Puertas enfrentadas',
  'Tipo de plegado',
  'Puerta con marco',
  'Cerradero',
  'Sentido apertura',
  'Tipo',
  'Perfiles en corner',
  'Vidrio Fijo',
  'Longitud Tirador',
  null,
  null,
  null,
]
