import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'

interface DateTimeProps extends PttFieldType {}

const Datetime = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label },
  validations,
  languages,
  innerForm,
  defaultLanguage,
}: DateTimeProps) => {

  const defaultValue = getDefaultValue({ name, storedData, translatable })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = e.target.value
    setValue({ ...value })
  }

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, name, onChange, translatable, validations])

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <input
        name={name}
        className={`w-full rounded px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } ${
          translatable && languages && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
        type="datetime-local"
        placeholder={label}
        value={`${value[language] ? (value[language] as string).replace(' ', 'T') : ''}`}
        onChange={onChangeText}
      />
    </Field>
  )
}

export default Datetime
