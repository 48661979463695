import React, { useState, useEffect } from 'react'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttModelField, PttFieldType, SelectOptionType } from '../../types'

interface ModelsProps extends PttFieldType {
  models: { [key: string]: PttModelField[] }
  options: {
    label: string
    multiple: boolean
    models: { [key: string]: string }
  }
  files: { [key: string]: string }
}

const Attributes = ({
  showErrors,
  storedData,
  onChange,
  models,
  name,
  translatable,
  options: { label, models: modelsAvailable, multiple },
  languages,
  innerForm,
  parentIsSorting,
  find,
  defaultLanguage,
  files,
}: ModelsProps) => {
  const [language] = useState(defaultLanguage)
  const [errors] = useState<string[]>()
  const defaultValue = getDefaultValue({
    name,
    storedData,
    translatable: false,
    shouldReturnDefault: true,
    defaultValue: {},
  })
  let [attributes, setAttributes] = useState<{
    [key: string]: string | string[]
  }>(defaultValue.default as { [key: string]: string | string[] })
  // console.log('🚀 ~ attributes:', attributes)
  const [value, setValue] = useState(defaultValue)
  // console.log('🚀 ~ value:', value)

  useEffect(() => {
    onChange(name, value, errors)
  }, [value, errors, name, onChange])

  useEffect(() => {
    value[language] = attributes
    setValue({ ...value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes])

  const setNewAttributeValue = (
    attributeId: string,
    attributeValueId: string
  ) => {
    console.log(multiple, 'MULTIPLE')
    if (multiple) {
      if (typeof attributes[attributeId] === 'undefined') {
        attributes[attributeId] = []
      }

      if (attributes[attributeId].includes(attributeValueId)) {
        attributes[attributeId] = (attributes[attributeId] as string[]).filter(
          val => val !== attributeValueId
        )
        if (attributes[attributeId].length === 0) {
          delete attributes[attributeId]
        }
      } else {
        ;(attributes[attributeId] as string[]).push(attributeValueId)
      }
    } else {
      console.log('no multiple?')
      if (attributes[attributeId] && !attributeValueId) {
        delete attributes[attributeId]
      } else {
        attributes[attributeId] = attributeValueId
      }
    }
    setAttributes({ ...attributes })
  }

  console.log(attributes, 'ATTRIBUTES')
  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setNewAttributeValue(e.target.getAttribute('id') as string, e.target.value)
  }

  const AttributeRow = ({ attribute }: { attribute: SelectOptionType }) => {
    return (
      <tr className='border-b table-auto border-grayLight'>
        <td className='px-2 py-2'>
          {attribute.title} ({attribute?.rawItem?.unit})
        </td>
        <td>
          <select
            value={multiple ? undefined : attributes[attribute._id]}
            id={attribute._id}
            onChange={onChangeSelect}
          >
            <option value=''>No value</option>
            {find?.AttributeValue.map(value => {
              if (value?.rawItem?.attribute === attribute.title) {
                return (
                  <option
                    // disabled={
                    //   multiple &&
                    //   attributes[attribute._id] &&
                    //   attributes[attribute._id].includes(value._id)
                    // }
                    key={value._id}
                    value={value._id}
                  >
                    {value.title}
                  </option>
                )
              }
              return null
            })}
          </select>
        </td>
        {multiple && (
          <tr className='flex items-center mt-2'>
            {attributes[attribute._id] &&
              (attributes[attribute._id] as string[]).map(selectedValue => (
                <span
                  onClick={() =>
                    setNewAttributeValue(attribute._id, selectedValue)
                  }
                  className='inline-block px-2 mr-2 cursor-pointer bg-grayLight hover:bg-gray'
                >
                  {
                    find?.AttributeValue.find(
                      value => value._id === selectedValue
                    )?.title
                  }
                </span>
              ))}
          </tr>
        )}
      </tr>
    )
  }

  console.log(find?.Attribute)

  return (
    <div className='p-2 border border-2 rounded md:py-2 md:px-2'>
      <div className='flex items-center justify-between mt-1 wght-semibold'>
        <span>{label}</span>
      </div>
      <div>
        <table className='w-full'>
          <tbody>
            <tr className='uppercase border-b table-auto border-grayLight bg-grayLight'>
              <td className='px-2 py-2'>Attribute</td>
              <td className='px-2 py-2'>Value</td>
              {multiple && <td className='px-2 py-2'>Selected</td>}
            </tr>
            {find?.Attribute.map(attribute => (
              <AttributeRow key={attribute._id} attribute={attribute} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Attributes
