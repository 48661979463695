const hexToRgbA = (hex: string, opacity = 1) => {
  var c;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(Number(c)>>16)&255, (Number(c)>>8)&255, Number(c)&255].join(',')+',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}

const colorFromString = function(str: string, opacity = 0.4) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
  }
  return hexToRgbA(colour, opacity);
}

export default colorFromString