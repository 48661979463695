import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Form from '../components/form/Form'
import {
  PttModelType,
  DataType,
  StoredDataType,
  UpdateDataType,
} from '../types'
import useLocalStorage from '../hooks/useLocalStorage'
import Button from '../components/Button'
import { useConfig } from '../store'

const flatten = require('lodash/flatten')

type BodyDataType = {
  modelName: string
  _id?: string
}

type SavedDataType = {
  _id: string
}

type LocationState = {
  page?: number
}

const Edit = () => {
  const [token, setToken] = useLocalStorage('token', null)

  const appConfig = useConfig()
  const navigate = useNavigate()
  const { name: modelName, id: urlId, groupName } = useParams()
  const location = useLocation()
  const locationState = location.state as LocationState

  const [id, setId] = useState<null | undefined | string>(urlId)
  const [data, setData] = useState<DataType | null>(null)
  const [name, setName] = useState<string>(modelName!)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState<{ [key: string]: unknown }>({})
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)

  const updateStored: UpdateDataType = (key, value, errs = []) => {
    if (!data) return

    if (data && data.stored && typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = name
    }

    const valueToStore = value.hasOwnProperty('default') ? value.default : value

    try {
      if (
        data.stored[key] === valueToStore ||
        JSON.stringify(data.stored[key]) === JSON.stringify(valueToStore)
      ) {
        return
      }
    } catch (e) {}

    data.stored[key] = valueToStore

    errors[key] = errs
    setData({ ...data } as DataType)
    setErrors({ ...errors })
  }

  useEffect(() => {
    if (data) {
      setTitle(
        `${id ? 'Edit' : 'Create'} ${data?.modelConfig?.title} - ${
          appConfig?.app?.title
        }`
      )
    }
  }, [data, id])

  useEffect(() => {
    setData(null)
    setName(modelName as string)

    const bodyData: BodyDataType = {
      modelName: modelName!,
    }

    if (id) {
      bodyData._id = id
    }

    const get = async () => {
      const res = await fetch(`${process.env.REACT_APP_API_URL}model/get`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      })

      if (res.status === 403) {
        setToken(null)
        navigate('/login')
        return
      }
      const responseData: DataType = await res.json()
      setData(responseData)
    }

    get()
  }, [modelName, id, navigate])

  const save = (type = 'default') => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else if (data) {
      setShowErrors(false)
      setSaving(true)
      const put = async () => {
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}model/update`,
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({ ...data.stored, modelName }),
            }
          )

          if (res.status === 403) {
            setToken(null)
            navigate('/login', { state: { prevPath: 'edit' } })
            return
          }

          setSaving(false)

          const responseData: SavedDataType = await res.json()
          if (typeof id === 'undefined') {
            setId(responseData._id)
            navigate(
              `/admin/${groupName ? groupName + '/' : ''}edit/${modelName}/${
                responseData._id
              }`,
              { state: { prevPath: 'edit' } }
            )
          }
          setSaved(true)
          setTimeout(() => setSaved(false), 3000)
        } catch (e) {
          console.log('Error', e)
        }
      }

      put()
    }
  }

  return (
    <div className='relative'>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex items-center justify-between mb-8'>
        <h2 className='text-xl wght-semibold'>{title}</h2>
      </div>
      {!data && <div className='mb-8'>Loading...</div>}
      {data && modelName === name && (
        <>
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
          />
          <div className='sticky bottom-0 left-0 right-0 z-10 z-40 flex items-center justify-between h-12 text-xs bg-white'>
            <div>
              <Button
                theme='dark'
                loading={saving ? 'Saving...' : null}
                onClick={save}
              >
                Save
              </Button>
              {saved && <span className='ml-4'>Saved successfully!</span>}
            </div>
            <Button
              theme='outline-dark'
              to={`/admin/${groupName ? groupName + '/' : ''}list/${modelName}`}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default Edit
