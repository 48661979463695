import React, { useState } from 'react'
import sha1 from 'js-sha1'
import {
  useNavigate,
  Link,
} from 'react-router-dom'

import Text from '../components/form/Text'
import Password from '../components/form/Password'
import { LoginDataType, ErrorsType, UpdateDataType } from '../types'
import useLocalStorage from '../hooks/useLocalStorage'
const flatten = require('lodash/flatten')

const Login = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<LoginDataType>({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [errors, setErrors] = useState<ErrorsType>({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  const [, setToken] = useLocalStorage('token', null)
  const [, setUser] = useLocalStorage('user', null)

  const updateData: UpdateDataType = (key, value, errs = []) => {
    
    const valueToStore = value.hasOwnProperty('default') ? value.default : value
    
    try {
      if (data[key] === valueToStore || JSON.stringify(data[key]) === JSON.stringify(valueToStore)) {
        return
      }
    } catch (e) {}
    
    data[key] = valueToStore

    setData({ ...data })
    errors[key] = errs
    setErrors({ ...errors })
  }
  
  const login = () => {
    setErrorMessage(false)
    const errs = flatten(errors)
    if (Object.keys(errs).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setLoading(true)

      const post = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}session/login`, {
          method: 'POST',
          body: JSON.stringify({
            username: data.username,
            password: sha1(data.password),
          }),
        })

        const responseData = await res.json()

        if (res.status !== 200) {
          setErrorMessage(responseData.error)
          return
        }

        setToken(responseData.token)
        setUser(responseData.user)
        navigate(`/admin/`, { replace: true })
      }

      post()
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2 lg:w-1/4">
        <h2 className="mb-4 text-xl text-center wght-semibold">Login</h2>
        <div>
          <div className="mb-4">
            <Text
              storedData={data}
              showErrors={showErrors}
              onChange={updateData}
              name="username"
              translatable={false}
              options={{ label: 'Username' }}
              validations={['not_blank']}
              defaultLanguage="default"
              languages={{}}
            />
          </div>
          <Password
            storedData={data}
            showErrors={showErrors}
            onChange={updateData}
            name="password"
            translatable={false}
            options={{ label: 'Password' }}
            validations={['not_blank']}
            hideRepeat={true}
            onKeyPress={(e: React.KeyboardEvent) => (e.key === 'Enter' && login())}
            defaultLanguage="default"
            languages={{}}
          />
          {errorMessage && <span className="block mt-4 text-error">{errorMessage}</span>}
        </div>
        <div className="flex items-end justify-center mt-4">
          {loading
            ? <span className="wght-bold">Loading...</span>
            : <span onClick={login} className="cursor-pointer wght-bold hover:wght-semibold hover:text-primary">Login &#8599;</span>
          }
        </div>
      </div>
      <div className="flex items-end mt-4">
        <Link
          to={`/recovery`}
          className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
          >Forgot your password?
        </Link>
      </div>
    </div>
  )
}

export default Login