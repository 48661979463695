import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'

interface CheckboxProps extends PttFieldType {}

const Checkbox = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label },
  validations,
  languages,
  defaultLanguage,
}: CheckboxProps) => {

  const defaultValue = getDefaultValue({name, storedData, translatable, shouldReturnDefault: true, defaultValue: '0'})
  const [language, setLanguage] = useState<string>(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxValue = e.target.checked
    value[language] = checkboxValue ? '1' : '0'
    setValue({ ...value })
  }

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, name, onChange, storedData, translatable, validations])

  return (
    <Field 
      type="checkbox"
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <input
        name={name}
        className={`ml-2 mt-2 rounded ${
          translatable ? 'rounded-tl-none' : ''
        } px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } bg-white outline-none placeholder-gray`}
        type="checkbox"
        checked={!!Number(value[language])}
        onChange={onChangeText}
        value="1"
      />
    </Field>
  )
}

export default Checkbox
