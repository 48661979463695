import React from 'react'

import Color from './Color'
import Text from './Text'
import Password from './Password'
import Textarea from './Textarea'
import Select from './Select'
import File from './File'
import Image from './Image'
import Number from './Number'
import Checkbox from './Checkbox'
import Models from './Models'
import Date from './Date'
import Time from './Time'
import Datetime from './Datetime'
import Json from './Json'
import Model from './Model'
import Attributes from './Attributes'
import Address from './Address'
import PlanInformation from './PlanInformation'
import PlanInformationCalculations from './PlanInformationCalculations'

type PttComponentType = {
  component: any,
  width: string
}

const components: { [key: string]: PttComponentType } = {
  color: {
    component: Color,
    width: '1/2',
  },
  model: {
    component: Model,
    width: 'full',
  },
  date: {
    component: Date,
    width: '1/2',
  },
  datetime: {
    component: Datetime,
    width: '1/2',
  },
  time: {
    component: Time,
    width: '1/2',
  },
  text: {
    component: Text,
    width: '1/2',
  },
  password: {
    component: Password,
    width: 'full',
  },
  number: {
    component: Number,
    width: '1/2',
  },
  textarea: {
    component: Textarea,
    width: 'full',
  },
  select: {
    component: Select,
    width: '1/2',
  },
  file: {
    component: File,
    width: '1/2',
  },
  image: {
    component: Image,
    width: '1/2',
  },
  checkbox: {
    component: Checkbox,
    width: '1/2',
  },
  models: {
    component: Models,
    width: 'full',
  },
  json: {
    component: Json,
    width: 'full',
  },
  attributes: {
    component: Attributes,
    width: 'full',
  },
  address: {
    component: Address,
    width: 'full'
  },
  planInformation: {
    component: PlanInformation,
    width: 'full'
  },
  planInformationCalculations: {
    component: PlanInformationCalculations,
    width: 'full'
  },
}

const componentWidth = (props: { [key: string]: unknown }) => {
  if (props.name === 'position') {
    return 'w-full'
  }
  return components[props.type as string].width
}

const componentClassName = (props: { [key: string]: unknown }) => {

  const width = componentWidth(props)

  let className = `flex-1 w-full flex-basis-full md:flex-grow-0 ${props.parentIsSorting ? 'mb-1 md:mb-2' : 'mb-4 md:mb-6'}`
  className += ` md:flex-basis-${width} md:w-${width} ${width === '1/2' ? 'md:pr-4' : ''}`
  if (props.type === 'hidden') {
    return 'hidden'
  }
  return className
}

const componentInnerClassName = (props: { [key: string]: unknown }) => {
  let className = ''
  if (props.name === 'position') {
    className += 'md:w-1/2 md:pr-2'
  }
  return className
}

const Wildcard = (props: { [key: string]: unknown }) => {
  const TagName = typeof components[props.type as string] !== 'undefined'
    ? components[props.type as string].component
    : null

  return TagName ? (
    <div className={componentClassName(props)}>
      <div className={componentInnerClassName(props)}>
        <TagName
          {...props}
          defaultLanguage={props?.translatable && props?.languages && typeof props?.languages === 'object' ? Object.keys(props.languages)[0] : 'default'}
        />
      </div>
    </div>
  ) : null
}

export default Wildcard
