import React, { useState, useEffect } from 'react'
import Form from './Form'
import generateToken from '../../lib/generateToken'

import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'

import { PttModelField, PttModelType } from '../../types'

interface ModelProps extends PttFieldType {
  options: {
    label: string
    model: string 
    dropdown: boolean 
    dropdownState: string
  },
  models: {[key: string]: PttModelField[]}
}

const Model = ({
  showErrors,
  storedData,
  models,
  name,
  translatable,
  options: { 
    label, 
    model, 
    dropdown, 
    dropdownState 
  },
  languages,
  defaultLanguage,
  onChange,
}: ModelProps) => {

  const defaultValue = getDefaultValue({ name, storedData, translatable, shouldReturnDefault: true, defaultValue: { key: generateToken(30) } })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [showContent, setShowContent] = useState(dropdown && dropdownState === 'hidden' ? false : true)

  const updateStored = ( modelName: string, key: string, v: {[key: string]: unknown}) => {

    const currentValue = value[language] as {[key: string]: {[key: string]: unknown}}
    const valueToStore = v.hasOwnProperty('default') ? v.default : v

    try {
      if (currentValue[key] === valueToStore || JSON.stringify(currentValue[key]) === JSON.stringify(valueToStore)) {
        return
      }
    } catch (e) {}

    (value[language] as {[key: string]: unknown})[key] = valueToStore

    setValue({...value})
  }

  useEffect(() => {
    onChange(name, value, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
       <div className={`border-2 border-black rounded ${dropdown && 'cursor-pointer'}`}>
          <div 
            className="flex items-center justify-between p-2 text-white bg-black select-none md:px-4"
            onClick={() => {
              if (dropdown) {
                setShowContent(!showContent)
              }
            }}
          >
            <span>{label}</span>
            {dropdown &&
              <div className={`w-4`}>
                <svg className="fill-current" style={{ transform: `${showContent? 'rotate(-90deg)' : 'rotate(90deg)'}`}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.002 512.002"><path d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z"/></svg>
              </div>
            }
          </div>
          <div className={`${dropdown && !showContent? 'h-0' : ''} overflow-hidden bg-grayLight`}>
            <div className="p-2 md:p-4">
              <Form
                innerForm={true}
                parentIsSorting={false}
                onChange={(n, v) => updateStored(model, n, v)}
                showErrors={showErrors}
                name={model}
                data={{ models, stored: value[language] as PttModelType, languages, find: {} }}
              />
            </div>
          </div>
        </div>
    </Field>
  )
}

export default Model
