import React, { useState } from 'react'
import {
  useNavigate,
  Link
} from 'react-router-dom'

import Text from '../components/form/Text'
import Password from '../components/form/Password'
import { LoginDataType, ErrorsType, UpdateDataType } from '../types'
const flatten = require('lodash/flatten')

const Reset = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<LoginDataType>({})
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [errors, setErrors] = useState<ErrorsType>({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateData: UpdateDataType = (key, value, errs = []) => {
    const valueToStore = value.hasOwnProperty('default') ? value.default : value
    
    try {
      if (data[key] === valueToStore || JSON.stringify(data[key]) === JSON.stringify(valueToStore)) {
        return
      }
    } catch (e) {}
    
    data[key] = value
    setData({ ...data })
    errors[key] = errs
    setErrors({ ...errors })
  }

  const send = () => {
    setErrorMessage(undefined)
    const errs = flatten(errors)
    if (Object.keys(data).length === 0) {
      setShowErrors(true)
      setErrorMessage('The password field is empty')
      return
    }
    if (Object.keys(errs).length > 0) {
      setShowErrors(true)
      setErrorMessage(Object.values(errs)[0] as string)
    } else {
      setShowErrors(false)
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}session/reset-password`, {
        method: 'POST',
        body: JSON.stringify({
          password: data.password,
          token: data.token
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setLoading(false)
          if (response.status === 200) {
            navigate(`/result`, { replace: true })
          } else {
            setErrorMessage(response.body.error)
          }
        })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2">
        <div>
          <h2 className="mb-4 text-xl text-center wght-semibold">We have emailed you a recovery code.</h2>
          <div>
            <div className="mb-4">
              <Text 
                storedData={data}
                showErrors={showErrors}
                onChange={updateData}
                name="token"
                translatable={false}
                options={{ label: 'Recovery code' }}
                validations={['not_blank']}
                defaultLanguage={'default'}
                languages={{}}
              />
            </div>
            <div className="mb-4">
              <Password
                storedData={data}
                showErrors={showErrors}
                onChange={updateData}
                name="password"
                translatable={false}
                options={{ label: 'New Password' }}
                validations={['not_blank', 'password', 'min_length']}
                hideRepeat={false}
                defaultLanguage={'default'}
                languages={{}}
              />
            </div>
            {errorMessage && <span className="block my-4 text-error">{errorMessage}</span>}
          </div>
          <div className="flex items-end justify-center">
            {loading
              ? <span className="wght-bold">Loading...</span>
              : <span onClick={send} className="cursor-pointer wght-bold hover:wght-semibold hover:text-primary">Send &#8599;</span>
            }
          </div>
        </div>
      </div>
      <div className="flex items-end mt-2 mb-8">
        <Link
            to={`/login`}
            className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
          >Cancel
        </Link>
      </div>
    </div>
  )
}

export default Reset