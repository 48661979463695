import React, { useState, useEffect } from 'react'
import ReactMde from 'react-mde'
import * as Showdown from 'showdown'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'

interface TextareaProps extends PttFieldType {
  options: {
    label: string
    markdown: boolean
  }
}

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const listCommands = [
  ['bold', 'italic', 'link', 'unordered-list', 'ordered-list'],
]

const Textarea = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label, markdown },
  validations,
  languages,
  innerForm,
  defaultLanguage,
}: TextareaProps) => {
  const defaultValue = getDefaultValue({ name, storedData, translatable })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')

  const onChangeText = (v: string) => {
    value[language] = v
    setValue({ ...value })
  }

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, name, storedData, onChange, translatable, validations])

  const textarea = markdown ? (
    <div
      className={`border ${
        showErrors && errors.length > 0 ? 'border-error' : 'border-grayLight'
      }`}
    >
      <ReactMde
        toolbarCommands={listCommands}
        value={`${value[language] || ''}`}
        onChange={onChangeText}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        generateMarkdownPreview={() =>
          Promise.resolve(converter.makeHtml(`${value[language] || ''}`))
        }
      />
    </div>
  ) : (
    <div>
      <textarea
        name={name}
        className={`w-full h-32 px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } rounded ${
          translatable && languages && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none resize-y`}
        placeholder={label}
        value={`${value[language] || ''}`}
        onChange={e => onChangeText(e.target.value)}
      />
    </div>
  )

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      {textarea}
    </Field>
  )
}

export default Textarea
