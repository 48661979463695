import { useState, ChangeEvent, useEffect, useRef } from 'react'
import Button from './Button'
import Portal from './Portal'
import useLocalStorage from '../hooks/useLocalStorage'
import { PiArrowLineDownBold, PiArrowLineUpBold } from 'react-icons/pi'
import * as XLSX from 'xlsx'
import { IoClose } from 'react-icons/io5'
import { LuLoader2 } from 'react-icons/lu'
import { PRODUCT_HEADER_ROW_1, PRODUCT_HEADER_ROW_2 } from '../contants'
import { mapProductToRow } from '../lib/mapProductToRow'

type OperationsInfoType = {
  operationsInformation: {
    inserts: number
    updates: number
  }
}

export default function ImportExport({ modelName }: { modelName: string }) {
  const [token, setToken] = useLocalStorage('token', null)
  const [uploading, setUploading] = useState(false)
  const [currentFile, setCurrentFile] = useState<File | null>(null)
  const [openResumeModal, setOpenResumeModal] = useState(false)
  const [operations, setOperations] = useState<OperationsInfoType | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCurrentFile(e.target.files[0])
    }
  }

  useEffect(() => {
    if (currentFile) {
      upload()
    } else {
      if (inputRef.current) inputRef.current.value = ''
    }
  }, [currentFile])

  const upload = async () => {
    setUploading(true)
    setOpenResumeModal(true)
    const reader = new FileReader()
    reader.readAsArrayBuffer(currentFile as Blob)
    reader.onload = async e => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const json = XLSX.utils.sheet_to_json(sheet, { defval: '' })
      const info = {
        data: json,
        type: modelName,
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}import`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(info),
      })
      const { processedData } = await response.json()
      setOperations(processedData)
      setUploading(false)
    }
  }

  const handleClickExport = async () => {
    const body = {
      type: modelName,
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}export`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`)
    }

    const { exportData } = await response.json()

    if (!exportData.length) {
      alert('No data to export')
      return
    }

    const wb = XLSX.utils.book_new()

    if (modelName === 'Product') {
      let ws_data: any[] = [PRODUCT_HEADER_ROW_1, PRODUCT_HEADER_ROW_2]

      exportData.forEach((product: any) => {
        ws_data.push(mapProductToRow(product))
      })

      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(ws_data)

      ws['!merges'] = [
        //'Description'
        { s: { r: 0, c: 2 }, e: { r: 0, c: 5 } },
        //'Ficha Técnica'
        { s: { r: 0, c: 7 }, e: { r: 0, c: 10 } },
        //'Manual de instrucciones de montaje'
        { s: { r: 0, c: 11 }, e: { r: 0, c: 14 } },
        //'Archivo CAD'
        { s: { r: 0, c: 15 }, e: { r: 0, c: 18 } },
        //'Archivo BIM'
        { s: { r: 0, c: 19 }, e: { r: 0, c: 22 } },
        //'Attributes'
        { s: { r: 0, c: 23 }, e: { r: 0, c: 47 } },
      ]

      XLSX.utils.book_append_sheet(wb, ws, 'Products')
    } else {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData)
      XLSX.utils.book_append_sheet(wb, ws, modelName)
    }

    const arrayBuffer: ArrayBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    })
    const blob: Blob = new Blob([arrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const url: string = window.URL.createObjectURL(blob)
    const a: HTMLAnchorElement = document.createElement('a')
    a.href = url
    a.download = `${body.type}.xlsx`
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  return (
    <>
      <div className='mb-8 flex justify-end items-center gap-4'>
        <Button theme='clear' onClick={() => handleClickExport()}>
          <label className='cursor-pointer'>
            <PiArrowLineUpBold className='inline-block mr-2' />
            Export
          </label>
        </Button>
        {modelName !== 'Estimate' && modelName !== 'Checkout' && (
          <Button theme='clear'>
            <label className='cursor-pointer'>
              <PiArrowLineDownBold className='inline-block mr-2' />
              Import
              <input
                ref={inputRef}
                type='file'
                accept='.xls, .xlsx'
                className='hidden'
                onChange={handleChange}
              />
            </label>
          </Button>
        )}
      </div>

      {openResumeModal && (
        <Portal>
          <div className='bg-white h-max w-[80vw] z-40 p-8'>
            <div className='flex justify-between items-center mb-8'>
              {uploading && !operations ? (
                <h2 className='text-2xl font-bold'>Importing...</h2>
              ) : (
                <h2 className='text-2xl font-bold'>Resume</h2>
              )}
              <button
                className='text-2xl font-bold'
                onClick={() => {
                  setOpenResumeModal(false)
                  setCurrentFile(null)
                }}
              >
                <IoClose />
              </button>
            </div>
            {uploading && !operations ? (
              <div className='flex justify-center items-center gap-2'>
                <span> Uploading...</span>
                <br />
                <LuLoader2 className='animate-spin' />
              </div>
            ) : (
              <div className='flex justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                  <p className='text-3xl font-bold'>
                    {operations?.operationsInformation.inserts}
                  </p>
                  <p className='text-sm'>Inserts</p>
                </div>
                <div className='flex flex-col justify-center items-center mx-8'>
                  <p className='text-3xl font-bold'>
                    {operations?.operationsInformation.updates}
                  </p>
                  <p className='text-sm'>Updates</p>
                </div>
              </div>
            )}
          </div>
        </Portal>
      )}
    </>
  )
}
