import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useConfig } from '../../store'
import useLocalStorage from '../../hooks/useLocalStorage'
import upload from '../../lib/upload'

const itemsPerPage = 72

const MediaViewer = ({
  value, onChange, onClose, type,
}) => {

  const [token, setToken] = useLocalStorage('token')
  const navigate = useNavigate()
  const appConfig = useConfig()
  const [data, setData] = useState()
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [imagesToUpload, setImagesToUpload] = useState(0)

  const selectImages = async () => {
    var input = document.createElement('input')
    input.type = 'file'
    input.multiple = true
    input.accept = type === 'image' ? 'image/*' : '*'

    input.onchange = e => {
      uploadFiles(Array.from(e.target.files))
    }

    input.click()
  }

  const uploadFiles = async (files) => {

    setImagesToUpload(files.length)

    if (files.length === 0) return

    const fileInfo = await upload(type, token, appConfig, files[0])
    if (!fileInfo) {
      console.log('Error uploading file', files[0])
    }

    files.shift()

    if (files.length > 0) {
      uploadFiles(files)
    } else {
      setImagesToUpload(0)
      if (page === 0) {
        getData()
      } else {
        setPage(0)
      }
    }
  }

  const getData = async () => {

    setLoading(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}media/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ type, page, itemsPerPage }),
    })

    setLoading(false)

    if (res.status === 403) {
      setToken(null)
      navigate('/login')
    } else if (res.status === 200) {
      try {
        const responseData = await res.json()
        setData(responseData)
      } catch (e) {
        console.log('Cannot list media', e)
      }
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const ImagePreview = ({ image, index }) => {
    const url = image
      ? `${appConfig.media.public}${appConfig.media.resized}${image.resizedName}`
      : null
    return (
      <div
        className="relative w-full bg-grayLight"
        style={{ paddingBottom: '100%' }}
      >
        <div
          onClick={image ? () => onChange(image) : null}
          className={`absolute inset-0 bg-center bg-no-repeat bg-cover ${image ? 'cursor-pointer' : ''}`}
          style={{ backgroundImage: `url(${url})` }}
        >
          {image.extension && (
            <div
              className="absolute bottom-0 right-0 p-1 m-2 text-xs text-center text-white"
              style={{ background: 'rgba(0, 0, 0, 0.6)' }}
            >
              {image.extension}
            </div>
          )}
        </div>
      </div>
    )
  }

  const FilePreview = ({ image }) => {
    const url = image
      ? `${appConfig.media.public}${image.name}`
      : ''
    return (
      <div
        className="col-span-1 md:col-span-2 lg:col-span-2"
      >
        <div>
          {image ? (
            <div
              className={`${image.id === value ? 'border-2 border-black' : ''
                } bg-grayLight block p-2 cursor-pointer`}
              title="Preview"
            >
              <span className='block'>
                <a className="hover:wght-semibold" target="_blank" href={url} rel="noreferrer">View {image && image.originalName}</a>
              </span>
              <span className='block hover:wght-semibold' onClick={image ? () => onChange(image) : null}>Select</span>
            </div>
          ) : (
            <div className="block p-2 bg-grayLight">&nbsp;</div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col p-8 bg-white">
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center">
          <h2 className="text-xl wght-semibold">
            {type === 'image' ? 'Images' : 'Files'}
          </h2>
          <button onClick={() => imagesToUpload === 0 ? selectImages() : null} className={`ml-4 rounded px-2 py bg-primary text-white text-sm ${imagesToUpload > 0 ? 'opacity-50' : ''}`}>
            Upload
          </button>
          {imagesToUpload > 0 && <span className="ml-4">Uploading files. {imagesToUpload} pending...</span>}
        </div>
        <div>
          <span
            className="cursor-pointer hover:wght-semibold"
            onClick={onClose}
          >
            Close
          </span>
        </div>
      </div>
      {loading && <div className="mb-8">Loading...</div>}
      <div className="flex-1 overflow-scroll">
        <div className="grid grid-cols-2 gap-1 md:grid-cols-4 lg:grid-cols-12">
          {!loading && data && data.items.map((image, index) => {
            if (type === 'image') {
              return <ImagePreview image={image} key={index} index={index} />
            }
            return <FilePreview image={image} key={index} index={index} />
          })}
        </div>
      </div>
      <div>
        {!loading && data && data.numberOfPages > 0 && data.items.length > 0 &&
          <div className="flex my-8">
            {Array.from({ length: data.numberOfPages }).map((_, index) => (
              <span key={index} onClick={() => setPage(index)} className={`mr-2 cursor-pointer hover:wght-semibold ${index === page ? 'wght-semibold' : ''}`}>{index + 1}</span>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export default MediaViewer
