import generateToken from './generateToken'
import { AppConfig } from '../types'

const updateMeta = async (
  token: string,
  fileInfo: { [key: string]: unknown }
) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}media/create`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(fileInfo),
  })

  const responseData = await res.json()

  return {
    _id: responseData.id,
    ...fileInfo,
  }
}

const upload = async (
  type: string,
  token: string,
  appConfig: AppConfig,
  file: File,
  callback = () => {},
  errorCallback = () => {}
): Promise<{ [key: string]: unknown } | null> => {
  const validations: { [key: string]: string[] } = {
    image: ['image/jpeg', 'image/png', 'image/gif'],
    file: [],
  }

  const { type: fileType } = file

  if (
    validations[fileType] &&
    validations[fileType].length > 0 &&
    !validations[fileType].includes(type)
  ) {
    alert(
      'Invalid file type. Allowed types: ' + Object.keys(validations).join(', ')
    )
    return null
  }

  const name =
    typeof file !== 'undefined' && typeof file.name === 'string'
      ? file.name
      : ''
  const parts = name.split('.')
  let extension
  if (parts.length > 0) {
    extension = parts.pop()
    if (extension) {
      extension = extension.toLowerCase()
    }
    if (!extension) {
      errorCallback()
      return null
    }
  }

  if (file && Number(file.size) / 1024 / 1024 > 20) {
    alert('Max file size 20MB')
    errorCallback()
    return null
  }

  const fileName = `${generateToken(40)}.${extension}`

  try {
    const res = await fetch(`${appConfig.media.url}request-upload`, {
      method: 'PUT',
      body: JSON.stringify({
        appName: appConfig.media.name,
        fileName,
        fileType: file.type,
      }),
    })

    const { data } = await res.json()

    const res2 = await fetch(data.url, {
      method: 'PUT',
      body: file,
    })

    if (res2.status !== 200) {
      throw new Error('Could not upload to AWS')
    }

    const fileNameParts = fileName.split('.')

    const fileInfo = {
      name: fileName,
      type: file.type,
      size: file.size,
      originalName: file.name,
      extension,
      resizable: data.resizable,
      quality: data.quality,
      sizes: data.sizes.join(','),
      resizedName: `${fileNameParts[0]}-w${data.sizes[0]}-q${data.quality}.${fileNameParts[1]}`,
      width: 0,
      height: 0,
    }

    if (['image/jpeg', 'image/png'].includes(fileInfo.type)) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = async () => {
          fileInfo.width = img.width
          if (img.width < parseInt(data.sizes[0])) {
            fileInfo.resizedName = `${fileNameParts[0]}-w0-q${data.quality}.${fileNameParts[1]}`
          }
          fileInfo.height = img.height
          const fileData = await updateMeta(token, fileInfo)
          resolve(fileData)
        }
      })
    } else {
      const fileData = await updateMeta(token, fileInfo)

      return fileData
    }
  } catch (e) {
    console.log('Error uploading', e)
    return {}
  }
}

export default upload
