import React, { FC, useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children: React.ReactElement<
    unknown,
    string | React.JSXElementConstructor<unknown>
  >
}

const Portal: FC<Props> = ({ children }) => {
  const body = useRef(document.querySelector('body'))

  useEffect(() => {
    const bodyRef = body.current

    if (!bodyRef) return

    bodyRef.style.overflow = 'hidden'

    return () => {
      bodyRef.style.overflow = 'auto'
    }
  }, [])

  if (!body.current) return null

  return createPortal(
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>,
    body.current
  )
}

export default Portal
