import React from 'react'
import LanguageSelector from './LanguageSelector'

type FieldProps = {
  setLanguage: (v: string) => void, 
  language?: string, 
  languages?: {[key: string]: string}, 
  children: React.ReactNode, 
  label: string | null, 
  translatable: boolean, 
  type?: string
}

const Field = ({
  setLanguage, language, languages, children, label, translatable, type,
}: FieldProps) => (
  <div>
    {label && <label className={`wght-semibold ${type === 'checkbox' ? '' : 'block'}`}>{label}</label>}
    {translatable ? <LanguageSelector
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      {children}
    </LanguageSelector> : children}
  </div>
)

export default Field
