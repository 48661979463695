import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, Link, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import printValue from '../lib/printValue'
import { useConfig } from '../store'
import ImportExport from '../components/ImportExport'

const List = () => {
  const { name: modelName, groupName } = useParams()
  const navigate = useNavigate()
  const appConfig = useConfig()
  const [searchParams, setSearchParams] = useSearchParams()
  const [sorting, setSorting] = useLocalStorage(`${modelName}-sorting`, null)
  const [token, setToken] = useLocalStorage('token', null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(modelName)
  const [page, setPage] = useState(searchParams?.page || 0)
  const [isMessages, setIsMessages] = useState(false)

  const canImportExport = ['Product', 'User', 'Estimate', 'Checkout', 'Product']

  const getData = async () => {
    setLoading(true)

    const body = {
      modelName,
      page,
    }

    body.itemsPerPage = appConfig?.app?.itemsPerPage || 50

    if (sorting) {
      body.sorting = sorting
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}model/list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
    setLoading(false)
    try {
      const responseData = await res.json()

      if (res.status === 403) {
        setToken(null)
        navigate('/login')
      } else if (res.status === 200) {
        setTitle(
          `List ${responseData.modelConfig.title} - ${responseData.config.app.title}`
        )
        setIsMessages(responseData.modelConfig.title === 'ContactMessage')
        const rawBody = { ...responseData }
        Object.keys(rawBody.modelConfig.list).forEach(key => {
          if (typeof rawBody.modelConfig.list[key] === 'object') {
            rawBody.modelConfig.list[key] = rawBody.modelConfig.list[key].label
          }
        })
        setData(responseData)
      }
    } catch (e) {
      console.log('Error listing', e)
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelName, sorting, page])

  useEffect(() => {
    setSearchParams({
      page,
    })
  }, [page, setSearchParams])

  const duplicate = async id => {
    if (window.confirm('Duplicate?')) {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}model/duplicate`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            modelName,
            _id: id,
          }),
        }
      )

      if (res.status === 403) {
        setToken(null)
        navigate('/login')
      } else if (res.status === 200) {
        await getData()
      }
    }
  }

  const remove = async id => {
    if (window.confirm('Delete?')) {
      const res = await fetch(`${process.env.REACT_APP_API_URL}model/delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          _id: id,
        }),
      })

      if (res.status === 403) {
        setToken(null)
        navigate('/login')
      } else if (res.status === 200) {
        await getData()
      }
    }
  }

  const handleSort = key => {
    if (sorting && sorting.key === key) {
      if (sorting.direction === 'asc') {
        setSorting(null)
      } else {
        setSorting({ key, direction: 'asc' })
      }
    } else {
      setSorting({ key, direction: 'desc' })
    }
  }

  const Actions = ({ id, modelName, index }) => {
    return (
      <div className='relative flex text-xs'>
        <Link
          className='mr-4 cursor-pointer hover:text-primary hover:wght-semibold'
          to={{
            pathname: `/admin/${
              groupName ? groupName + '/' : ''
            }edit/${modelName}/${id}`,
            state: { index: (index + 1) * 10, page },
          }}
        >
          {!isMessages ? `Edit` : `View`}
        </Link>
        {!isMessages && (
          <span
            className='block mr-4 cursor-pointer hover:text-primary hover:wght-semibold'
            onClick={() => duplicate(id)}
          >
            Clone
          </span>
        )}
        <span
          className='block cursor-pointer hover:text-primary hover:wght-semibold'
          onClick={() => remove(id)}
        >
          Delete
        </span>
      </div>
    )
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex items-center justify-between mb-8'>
        <h2 className='text-xl wght-semibold'>{title}</h2>
        {!loading && data && !isMessages && (
          <Link
            to={{
              pathname: `/admin/${
                groupName ? groupName + '/' : ''
              }edit/${modelName}`,
            }}
            className='px-2 py-1 text-xs rounded cursor-pointer text-secondary bg-primary hover:wght-semibold'
          >
            Create {data.modelConfig.title}
          </Link>
        )}
      </div>

      {!loading && canImportExport.includes(modelName) ? (
        <ImportExport modelName={modelName} />
      ) : null}

      {loading && <div>Loading...</div>}
      {!loading && data && data.items.length === 0 && (
        <div>Nothing to list</div>
      )}
      {!loading && data && data.items.length > 0 && (
        <table className='w-full table-auto'>
          <thead className='w-full text-left bg-white border-b border-grayLight'>
            <tr>
              {modelName === 'Checkout' ? (
                <>
                  <th className='px-2 py-2 text-left wght-semibold bg-grayLighter'>
                    Email
                  </th>
                  <th className='px-2 py-2 text-left wght-semibold bg-grayLighter'>
                    Nif
                  </th>
                </>
              ) : null}
              {Object.keys(data.modelConfig.list).map(
                key =>
                  key !== '_id' && (
                    <th
                      key={key}
                      className='py-2 px-2 border-r border-grayLight bg-grayLighter'
                    >
                      <span
                        className='relative cursor-pointer wght-semibold'
                        onClick={() => handleSort(key)}
                      >
                        {data.modelConfig.list[key]}
                        {sorting && sorting.key === key && (
                          <span className='pl-2'>
                            {sorting.direction === 'asc' ? '↑' : '↓'}
                          </span>
                        )}
                      </span>
                    </th>
                  )
              )}
              <th className='px-2 py-2 text-right wght-semibold bg-grayLighter'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((item, index) => (
              <tr
                key={item._id}
                className='relative bg-white border-b border-grayLight hover:bg-grayLighter'
              >
                {modelName === 'Checkout' && (
                  <>
                    <td className='py-2 px-2 border-r border-grayLight'>
                      {item.user?.email}
                    </td>

                    <td className='py-2 px-2 border-r border-grayLight'>
                      {item.user?.nif}
                    </td>
                  </>
                )}
                {Object.keys(data.modelConfig.list).map(
                  key =>
                    key !== '_id' && (
                      <td
                        key={key}
                        className='py-2 px-2 border-r border-grayLight'
                      >
                        {typeof item[key] !== 'undefined' &&
                          printValue({ item, key })}
                      </td>
                    )
                )}
                <td className='table-cell py-2 text-right'>
                  <div className='flex px-2 justify-end'>
                    <Actions
                      modelName={modelName}
                      id={item._id}
                      index={index}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!loading && data.numberOfPages > 0 && data.items.length > 0 && (
        <div className='flex my-8'>
          {Array.from({ length: data.numberOfPages }).map((_, index) => (
            <span
              key={index}
              onClick={() => setPage(index)}
              className={`mr-2 cursor-pointer hover:wght-semibold ${
                index === page ? 'wght-semibold' : ''
              }`}
            >
              {index + 1}
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

export default List
