/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import MediaViewer from './MediaViewer'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'
import upload from '../../lib/upload'
import { useConfig } from '../../store'
import useLocalStorage from '../../hooks/useLocalStorage'

interface ImageProps extends PttFieldType { }

const Image = ({
  storedData,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  defaultLanguage,
}: ImageProps) => {
  const defaultValue = getDefaultValue({ name, storedData, translatable })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState<{ [key: string]: unknown }>(defaultValue)

  const appConfig = useConfig()
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [showMediaView, setShowMediaViewer] = useState(false)
  const [imageUrl, setImageUrl] = useState(value && value[language] ? `${appConfig.media.public}${appConfig.media.resized}${(value[language] as { [key: string]: unknown }).resizedName}` : null)
  const [ready, setReady] = useState(value && value[language] ? true : false)
  const [token] = useLocalStorage('token', null)

  const updateValue = (v: { [key: string]: unknown } | null) => {
    value[language] = v
    setValue({ ...value })
    setShowMediaViewer(false)
  }

  const remove = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Confirm?')) {
      updateValue(null)
    }
  }

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setDragging(false)

    if (e.dataTransfer.files.length === 0) return

    setUploading(true)

    const fileInfo = await upload('image', token, appConfig, e.dataTransfer.files[0])

    if (!fileInfo) {
      setUploading(false)
    }

    updateValue(fileInfo)

    let timer = 0
    const imageIsAvailable = setInterval(async () => {
      const res = await fetch(`${appConfig.media.public}${appConfig.media.resized}${fileInfo?.resizedName}`)
      if (res.status === 200) {
        setReady(true)
        clearInterval(imageIsAvailable)
        setUploading(false)
      } else {
        timer++
        if (timer > 5) {
          setUploading(false)
          clearInterval(imageIsAvailable)
          alert('Upload request timeout')
        }
      }
    }, 3000)
  }

  useEffect(() => {
    onChange(name, value, Errors(value, validations, translatable, languages))
  }, [value])

  useEffect(() => {
    if (value[language]) {
      setImageUrl(`${appConfig.media.public}${appConfig.media.resized}${(value[language] as { [key: string]: unknown }).resizedName}`)
    } else {
      setImageUrl(null)
    }
  }, [value])

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <div className="max-w-input">
        <div
          className="relative flex items-center px-4 rounded cursor-pointer bg-primary"
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDragOver={(e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            onDrop(e)
            setDragging(false)
          }}
        >
          <div
            onClick={remove}
            className="absolute top-0 right-0 mt-1 mr-2 text-secondary"
          >
            ✕
          </div>
          <div className="relative flex items-center justify-center w-24 h-24 overflow-hidden rounded-tl rounded-bl">
            {imageUrl && ready && <img className="rounded-l" alt="Preview" src={imageUrl} />}
            {uploading && <img className="absolute w-6" alt="Preview" src={`${process.env.PUBLIC_URL}/assets/images/spinner.gif`} />}
          </div>
          <div
            className={`${dragging ? '' : ''
              } text-xs flex justify-center items-center flex-1 text-secondary`}
            onClick={() => {
              if (!uploading) {
                setShowMediaViewer(true)
              }
            }}
          >
            <span className="text-center pointer-events-none">
              {uploading && 'Uploading...'}
              {!uploading && !dragging && <span>
                Drop image here
                <span className="block text-xxs">or view media gallery</span>
              </span>}
              {dragging && !uploading && <span>
                Drop to upload
              </span>}
            </span>
          </div>
        </div>
        {showMediaView && (
          <MediaViewer
            type="image"
            value={value ? value[language] : null}
            onChange={(v: { [key: string]: unknown }) => updateValue(v)}
            onClose={() => setShowMediaViewer(false)}
          />
        )}
      </div>
    </Field>
  )
}

export default Image
