import React from 'react'
import Wildcard from './Wildcard'
import {PttModelField, DataType, UpdateDataType} from '../../types'

type FormProps = {
  name: string 
  data: DataType
  onChange: UpdateDataType 
  showErrors: boolean 
  mode?: 'edit' | 'create' 
  innerForm?: boolean
  parentIsSorting?: boolean
}

const Form = ({
  name, 
  data, 
  onChange, 
  showErrors, 
  mode = 'edit', 
  innerForm = false, 
  parentIsSorting = false,
}: FormProps) => (
  <div className={`${innerForm ? '' : 'bg-white'}`}>
    <div className="flex flex-wrap">
      {typeof data.models[name] !== 'undefined'
        && data.models[name].map((item: PttModelField) => (
          <Wildcard
            mode={mode}
            onChange={onChange}
            showErrors={showErrors}
            storedData={data.stored}
            languages={data.languages}
            key={item.name}
            {...item}
            find={data.find}
            models={['models', 'model'].includes(item.type) ? data.models : null}
            innerForm={innerForm}
            parentIsSorting={parentIsSorting}
          />
        ))}
    </div>
  </div>
)

export default Form
