import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import upload from '../../lib/upload'
import { useConfig } from '../../store'
import { PttFieldType } from '../../types'
import useLocalStorage from '../../hooks/useLocalStorage'
import Field from './Field'
import MediaViewer from './MediaViewer'

interface FileProps extends PttFieldType { }

const File = ({
  storedData,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  defaultLanguage,
}: FileProps) => {
  const defaultValue = getDefaultValue({ name, storedData, translatable })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)

  const appConfig = useConfig()
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [showMediaView, setShowMediaViewer] = useState(false)
  const [fileUrl, setFileUrl] = useState(value && value[language] ? `${appConfig.media.public}${(value[language] as { [key: string]: unknown }).name}` : null)
  const [token] = useLocalStorage('token', null)

  const updateValue = (v: { [key: string]: unknown } | null) => {
    value[language] = v
    setValue({ ...value })
    setShowMediaViewer(false)
  }

  const remove = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Confirm?')) {
      updateValue(null)
    }
  }

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.dataTransfer.files.length === 0) return

    setUploading(true)

    const fileInfo = await upload('image', token, appConfig, e.dataTransfer.files[0])

    if (!fileInfo) {
      setUploading(false)
    }

    updateValue(fileInfo)

    let timer = 0
    const imageIsAvailable = setInterval(async () => {
      const res = await fetch(`${appConfig.media.public}${fileInfo?.name}`)
      if (res.status === 200) {
        clearInterval(imageIsAvailable)
        setUploading(false)
      } else {
        timer++
        if (timer > 5) {
          setUploading(false)
          clearInterval(imageIsAvailable)
          alert('Upload request timeout')
        }
      }
    }, 3000)
  }

  useEffect(() => {
    onChange(name, value, Errors(value, validations, translatable, languages))
  }, [value, languages, name, onChange, translatable, validations])

  useEffect(() => {
    if (value[language]) {
      setFileUrl(`${appConfig.media.public}${(value[language] as { [key: string]: unknown }).name}`)
    } else {
      setFileUrl(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <div className="max-w-input">
        <div
          className="relative flex items-center px-4 rounded cursor-pointer bg-primary"
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDragOver={(e: React.MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onDrop={(e: React.DragEvent<HTMLDivElement>) => {
            onDrop(e)
            setDragging(false)
          }}
        >
          <div
            onClick={remove}
            className="absolute top-0 right-0 mt-1 mr-2 text-secondary"
          >
            ✕
          </div>
          <div className="relative flex items-center justify-center w-24 h-24 overflow-hidden rounded-tl rounded-bl">
            {fileUrl && <a target="_blank" rel="noreferrer" className="rounded-l text-secondary" href={fileUrl}>View file</a>}
            {uploading && <img className="absolute w-6" alt="Preview" src={`${process.env.PUBLIC_URL}/assets/images/spinner.gif`} />}
          </div>
          <div
            className={`${dragging ? '' : ''
              } text-xs flex justify-center items-center flex-1 text-secondary`}
            onClick={() => {
              if (!uploading) {
                setShowMediaViewer(true)
              }
            }}
          >
            <span className="text-center pointer-events-none">
              {uploading && 'Uploading...'}
              {!uploading && !dragging && <span>
                Drag file here
                <span className="block text-xxs">or view media gallery</span>
              </span>}
              {dragging && !uploading && <span>
                Drop to upload
              </span>}
            </span>
          </div>
        </div>
        {showMediaView && (
          <MediaViewer
            type="file"
            value={value[language]}
            onChange={(v: { [key: string]: unknown }) => updateValue(v)}
            onClose={() => setShowMediaViewer(false)}
          />
        )}
      </div>
    </Field>)
}

export default File
