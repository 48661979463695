import React, { useState, useEffect, useRef } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'
import { JsonEditor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import JSONEditor from 'jsoneditor';

const defaultJSON = `
{
  "config": {
    "sort": {
      "position": "asc"
    },
    "list": {
      "position": "Position",
      "title": "Name"
    },
    "title": "ModelName"
  },
  "fields": [
    {
      "name": "position",
      "type": "number",
      "options": {
        "label": "Position"
      }
    },
    {
      "name": "title",
      "type": "text",
      "translatable": true,
      "options": {
        "label": "Title"
      }
    }
  ]
}
`

interface JSONProps extends PttFieldType {}

const Json = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label },
  validations,
  languages,
  innerForm,
  defaultLanguage,
}: JSONProps) => {

  const defaultValue = getDefaultValue({ name, storedData, translatable, shouldReturnDefault: true, defaultValue: defaultJSON })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)

  const [errors, setErrors] = useState<string[]>([])
  const inputJson = useRef<HTMLTextAreaElement>(null);
  const jsonEditor = useRef<{ jsonEditor: JSONEditor }>(null)
  const [textareaValue, setTextareaValue] = useState<string>(JSON.stringify(value[language], undefined, 2))

  const onChangeText = (v: {[key: string]: unknown}) => {
    value[language] = v
    setValue({ ...value })
  }

  // useEffect(() => {
  //   try {
  //     if (value && jsonEditor.current) {
  //       const currentValue = value[language]
  //       console.log('currentValue', currentValue)
  //       console.log('newValue', JSON.stringify(jsonEditor.current.jsonEditor.get()))
  //       if (typeof currentValue === 'string' && JSON.stringify(jsonEditor.current.jsonEditor.get()) !== currentValue) {
  //         jsonEditor.current.jsonEditor.set(JSON.parse(currentValue))
  //         jsonEditor.current.jsonEditor.expandAll()
  //       }
  //     }
  //   } catch (e) {
  //     console.log('Error', e)
  //   }
  // }, [value, language])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, name, onChange, storedData, translatable, validations])

  const textarea = (
    <div>
      <JsonEditor
        ref={jsonEditor}
        value={value && typeof value[language] !== 'undefined' ? typeof value[language] === 'string' ? JSON.parse(value[language] as string) : value[language] : {}}
        onChange={(e: {}) => {
          onChangeText(e)
        }}
        name={name}
        className={`w-full h-32 px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''
          } rounded ${translatable && languages && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
          } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none resize-y`}
        placeholder={label}
        onError={(e: any) => {
          console.log('Error', e)
        }}
      />
    </div>
  )

  const [notificationText, setNotificationText] = useState('')
  const [showJsonTextArea, setShowJsonTextArea] = useState(false)

  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  const copyJSON = () => {
    navigator.clipboard.writeText(JSON.stringify(value[language], undefined, 2))
    setNotificationText(`Copied to clipboard`)
  }

  const insertJSON = () => {
    try {
      const data = JSON.parse(textareaValue)
      if (textareaValue) {
        if (jsonEditor.current) {
          jsonEditor.current.jsonEditor.set(data)
          jsonEditor.current.jsonEditor.expandAll()
        }
        onChangeText(data as {[key: string]: unknown})
        setShowJsonTextArea(false)
      }
    } catch (e) {
      console.log('Invalid JSON')
    }
  }

  useEffect(() => {
    if (jsonEditor.current) jsonEditor.current.jsonEditor.expandAll()
  }, [])

  return (
    <div>
      {showJsonTextArea && <div className="fixed inset-0 z-40 flex items-center justify-center " style={{ backgroundColor: 'rgba(255,255,255,.8)' }}>
        <div className='absolute inset-0 ' onClick={() => setShowJsonTextArea(false)} />
        <div className='w-1/2 bg-white border rounded border-gray'>
          <div className='relative flex flex-col items-end p-4 pt-2'>
            <div className='w-full'>
              <span className="w-full cursor-pointer hover:text-primary hover:wght-semibold" onClick={copyJSON}>Copy JSON</span>
              <span className='ml-2 text-xs cursor-default hover:text-black hover:wght-normal'>{notificationText}</span>
            </div>
            <textarea name="" id="" cols={30} rows={20} className='w-full p-2 mb-2 font-mono text-xs border border-black rounded' value={textareaValue} onChange={(e) => {
              setTextareaValue(e.target.value)
            }} ref={inputJson}></textarea>
            <div className='flex justify-end'>
              <span
                onClick={() => setShowJsonTextArea(false)}
                className="px-2 py-0.5 mx-1 text-red border border-red rounded cursor-pointer  bg-white hover:bg-grayLighter"
              >
                Cancel
              </span>
              <span
                onClick={insertJSON}
                className="px-2 py-0.5 mx-1 text-white rounded cursor-pointer  bg-primary hover:bg-black"
              >
                Insert
              </span>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="flex items-center justify-between">
        <label className="wght-semibold">{label}</label>
        <div className='flex'>
          <span className="h-full cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => setShowJsonTextArea(true)}>JSON</span>
        </div>
      </div>
      <Field
        label={null}
        translatable={translatable}
        language={language}
        languages={languages}
        setLanguage={(v: string) => setLanguage(v)}
      >{textarea}</Field>
    </div>
  )
}

export default Json
