import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'

interface ColorProps extends PttFieldType {}

const Color = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label },
  validations,
  languages,
  innerForm,
  defaultLanguage
}: ColorProps) => { 
  
  const defaultValue = getDefaultValue({ name, storedData, translatable })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    value[language] = `${e.target.value.replace('#', '')}`
    setValue({ ...value })
  }

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, storedData, translatable, onChange, validations, languages, name])

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <div className="flex bg-white rounded">
        <div className="w-6 border-r rounded-l border-grayLight" style={{ backgroundColor: `#${value[language]}` }}></div>
        <input
          name={name}
          className={`w-full rounded ${
            translatable && languages && Object.keys(languages)[0] === language
              ? 'rounded-tl-none'
              : ''
          } px-2 py-1 ${
            showErrors && errors.length > 0 ? 'border border-error' : ''
          } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
          type="text"
          placeholder={label}
          value={`${value[language] || ''}`}
          onChange={onChangeText}
        />
      </div>
    </Field>
  )
}

export default Color
