import { PttFieldError } from '../../types'

type ValidationType = (
  value: string | {[key: string]: unknown},
  translatable?: boolean,
  languages?: {[key: string]: string},
  extraData?: {[key: string]: unknown}
) => boolean

const not_empty: ValidationType = (value) => {
  return typeof value === 'string' && value !== "-1"
}

const not_blank: ValidationType = (value, translatable, languages) => {
  const validation = (v: string): boolean => typeof v !== 'undefined' && v.length > 0 && value !== "-1"
  if (translatable && typeof value === 'object' && languages) {
    let valid = true
    Object.keys(languages).forEach(language => {
      if (value) {
        if (!validation(value[language] as string)) {
          valid = false
        }
      }
    })
    return valid
  }
  return typeof value === 'string' ? validation(value) : false
}

const password: ValidationType = (value, translatable, languages, extraData) => {
  if (typeof extraData === 'object' && extraData.mode === 'edit' && value === '') return true
  return typeof extraData === 'object' && typeof value === 'string' && value === extraData.repeated
}

const password_min_length: ValidationType = (value, translatable, languages, extraData) => {
  if (typeof extraData === 'object' && extraData.mode === 'edit' && value === '') return true
  return typeof value === 'string' && value.length > 5
}

const min_length: ValidationType = (value) => {
  return typeof value === 'string' && value.length > 5
}

const validationTypes: {[key: string]: ValidationType} = {
  not_empty,
  not_blank,
  password,
  password_min_length,
  min_length,
}

const Errors = (value: string | {[key: string]: unknown}, validations: PttFieldError[], translatable: boolean, languages?: {[key: string]: string}, extraData: {[key: string]: unknown} = {}): string[] => {
  const valid: string[] = []
  if (validations && Array.isArray(validations)) {
    validations.forEach(validation => {
      if (
        typeof validationTypes[validation] !== 'undefined'
        && !validationTypes[validation](value, translatable, languages, extraData)
      ) {
        valid.push(validation)
      }
    })
  }
  return valid
}

export default Errors
