import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'

interface NumberProps extends PttFieldType {}

const Number = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label, decimals = false },
  validations,
  languages,
  parentIsSorting,
  innerForm,
  defaultLanguage,
}: NumberProps) => {

  const defaultValue = getDefaultValue({ name, storedData, translatable })
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value || ''
    let newNumberValue
    if (newValue && decimals > 0) {
      newNumberValue = parseFloat(newValue)
    } else if (newValue) {
      newNumberValue = parseInt(newValue, 10)
    }
    value[language] = newNumberValue
    setValue({ ...value })
  }

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, name, onChange, translatable, validations])

  if (parentIsSorting && name === 'position') {
    return null
  }

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <input
        name={name}
        className={`w-full rounded px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''
          } ${translatable && languages && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
          } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
        type="number"
        step={`${decimals ? `0.${decimals}` : '1'}`}
        placeholder={label}
        value={`${value[language] || ''}`}
        onChange={onChangeText}
      />
    </Field>
  )
}

export default Number
