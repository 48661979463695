import { useState, useEffect } from 'react'
import colorFromString from '../../lib/colorFromString'
import getDefaultValue from '../../lib/getDefaultValue'
import generateToken from '../../lib/generateToken'
import { PttModelField, PttModelType, PttFieldType, UpdateStoredModels } from '../../types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { arrayMoveImmutable } from 'array-move'
import ModelsRow from './ModelsRow'

interface ModelsProps extends PttFieldType {
  models: { [key: string]: PttModelField[] }
  options: {
    label: string
    decimals: number
    models: { [key: string]: string }
  }
  files: { [key: string]: string },
}

const Models = ({
  showErrors,
  storedData,
  onChange,
  models,
  name,
  translatable,
  options: { label, models: modelsAvailable },
  languages,
  innerForm,
  parentIsSorting,
  find,
  defaultLanguage,
  files,
}: ModelsProps) => {


  const [sorting, setSorting] = useState(false)
  const [language] = useState(defaultLanguage)
  const [errors] = useState<string[]>()
  const defaultValue = getDefaultValue({ name, storedData, translatable: false, shouldReturnDefault: true, defaultValue: [] })
  if (!Array.isArray(defaultValue[language])) {
    defaultValue[language] = []
  }

  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    onChange(name, value, errors)
  }, [value, errors, name, onChange])

  const deleteModel = (index: number) => {
    if (window.confirm('Delete item?')) {
      (value[language] as PttModelType[]).splice(index, 1)
      setValue({ ...value })
    }
  }

  const updateStored: UpdateStoredModels = (index, modelName, key, v, errs) => {

    const valueToStore = typeof v !== 'undefined' && (v as { [key: string]: unknown }).hasOwnProperty('default') ? (v as { [key: string]: unknown }).default : v
    const currentValue = (value[language] as PttModelType[])[index] as { [key: string]: unknown }

    try {
      if (currentValue[key] === valueToStore || JSON.stringify(currentValue[key]) === JSON.stringify(valueToStore)) {
        return
      }
    } catch (e) { }

    const generatedKey = generateToken(30)
    if (typeof (value[language] as PttModelType[])[index] === 'undefined') {
      (value[language] as PttModelType[])[index] = { modelName, position: 0, key: generatedKey, id: generatedKey }
    }

    (value[language] as PttModelType[])[index][key] = valueToStore
    setValue({ ...value })
  }

  const addModel = (modelKey: string) => {

    const currentValue = value[language] as PttModelType[]

    let position = (currentValue.length + 1) * 10
    if (
      currentValue.length > 0
      && typeof currentValue[currentValue.length - 1].position !== 'undefined'
      && currentValue[currentValue.length - 1].position > 0
    ) {
      position = currentValue[currentValue.length - 1].position + 10
    }
    const key = generateToken(30)
    currentValue.push({ modelName: modelKey, key, id: key, position })
    value[language] = currentValue

    setValue({ ...value })
  }

  const moveModel = (dragIndex: number, hoverIndex: number) => {
    const sortedValue = arrayMoveImmutable(value.default as PttModelType[], dragIndex, hoverIndex)
    sortedValue.map((item, index) => {
      item.position = (index + 1) * 10
      return item
    })
    value.default = sortedValue
    setValue({ ...value })
  }

  return (
    <div className="p-2 bg-black rounded md:py-2 md:px-2">
      <div className="flex items-center justify-between mt-1 text-white">
        <span>{label} {parentIsSorting ? '(Exit sorting mode to edit)' : ''}</span>
      </div>
      {!parentIsSorting && <>
        <div
          className={`${!innerForm ? 'sticky top-0 z-30' : ''
            }  flex mt-1 overflow-scroll text-xs bg-black lg:overflow-hidden flex-wrap`}
        >
          <div
            onClick={() => setSorting(!sorting)}
            className={`p-1 my-1 mr-2 bg-white rounded cursor-pointer uppercase ${sorting ? 'bg-orange' : ''
              }`}
          >
            {sorting ? 'Exit sorting mode' : 'Sort'}
          </div>
          {!sorting
            && Object.keys(modelsAvailable).map(modelKey => (
              <span
                key={modelKey}
                onClick={() => addModel(modelKey)}
                className="flex items-center block p-1 my-1 mr-2 text-black bg-white rounded cursor-pointer hover:wght-semibold"
              >
                <span>{modelsAvailable[modelKey]}</span>
                <span
                  style={{ backgroundColor: colorFromString(modelKey, 0.4) }}
                  className="block w-4 h-4 ml-2 rounded-full"
                ></span>
              </span>
            ))}
        </div>
        <DndProvider
          backend={HTML5Backend}
        >
          {value[language] !== 'undefined' && Array.isArray(value[language]) && (value[language] as PttModelType[]).map((v: PttModelType, index: number) => (
            <ModelsRow
              key={v.key}
              moveModel={moveModel}
              models={models}
              languages={languages}
              sorting={sorting}
              showErrors={showErrors}
              index={index}
              item={v}
              find={find}
              updateStored={updateStored}
              modelsAvailable={modelsAvailable}
              deleteModel={deleteModel}
            />
          ))}
        </DndProvider>
      </>
      }
    </div>
  )
}

export default Models
