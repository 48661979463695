import React, {useState, useEffect} from 'react'
import { Routes, Route, Navigate, BrowserRouter} from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { Helmet } from 'react-helmet'
import Login from './Login'
import Recovery from './Recovery'
import Reset from './Reset'
import ResetResult from './ResetResult'
import Main from './Main'
import useLocalStorage from '../hooks/useLocalStorage'

type PttDecodedToken = {
  exp: number
}

const App = () => {

  const [token] = useLocalStorage('token', null)
  const [tokenIsValid, setTokenIsValid] = useState<Boolean | null>(null)

  useEffect(() => {
    if (tokenIsValid === null) {
      if (token && token !== 'undefined') {
        const decoded = jwt_decode<PttDecodedToken>(token)
        setTokenIsValid(decoded && (Date.now() < decoded?.exp * 1000))
      } else {
        setTokenIsValid(false)
      }
    }
  }, [token, tokenIsValid])

  return (
    <>
      <Helmet>
        <title>Log In</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={tokenIsValid ? <Navigate to="/admin/" /> : <Navigate to="/login" />}
          />
          <Route
            path="/login"
            element={tokenIsValid ? <Navigate to="/admin/" /> : <Login />}
          />
          <Route
            path="/recovery"
            element={tokenIsValid ? <Navigate to="/admin/" /> : <Recovery />}
            />
          <Route
            path="/reset"
            element={tokenIsValid ? <Navigate to="/admin/" /> : <Reset />}
            />
          <Route
            path="/result"
            element={tokenIsValid ? <Navigate to="/admin/" /> : <ResetResult />}
            />
          <Route
            path="/admin*"
            element={<Main tokenIsValid={tokenIsValid} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
