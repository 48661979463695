import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import { PttFieldType, PttFieldTypeOptions, SelectOptionType } from '../../types'
import getDefaultValue from '../../lib/getDefaultValue'
import Field from './Field'

interface SelectPropsOptions extends PttFieldTypeOptions {
  type: 'static' | 'model'
  multiple?: boolean
  model?: string
  options: { [key: string]: string }
}

interface SelectProps extends PttFieldType {
  options: SelectPropsOptions
}

const Select = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: {
    label,
    type,
    model,
    multiple = false,
    options: staticOptions,
  },
  validations,
  defaultLanguage,
  languages,
  find,
  innerForm,
}: SelectProps) => {

  const defaultValue = getDefaultValue({ name, storedData, translatable, shouldReturnDefault: multiple, defaultValue: multiple ? [] : undefined })
  const [value, setValue] = useState(defaultValue)
  const [language, setLanguage] = useState<string>(defaultLanguage)
  const [options, setOptions] = useState<SelectOptionType[]>([{ _id: '-1', title: label }])
  const [errors, setErrors] = useState<string[]>([])

  const shouldAddMultipleValue = (val: string[], v: string) => {
    if (!val.includes(v)) {
      val.push(v)
    }
    return [...val]
  }

  const onChangeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    value[language] = multiple ? shouldAddMultipleValue(value[language] as string[], e.target.value) : e.target.value
    setValue({ ...value })
  }

  const removeItem = (id: string) => {
    const valueItems = value[language] as string[]
    value[language] = valueItems.filter((item: string) => item !== id)
    setValue({ ...value })
  }

  useEffect(() => {
    if (type === 'static') {
      setOptions((o: SelectOptionType[]) => [
        ...Object.keys(staticOptions).map((key: string) => ({
          _id: key,
          title: `${staticOptions[key]}`,
        })),
      ])
    } else {
      setOptions([{ _id: '-1', title: 'Loading...' }])
      if (typeof model !== 'undefined' && typeof find !== 'undefined' && typeof find[model] !== 'undefined') {
        setOptions([{ _id: '-1', title: label }, ...find[model]])
      }
    }
  }, [name, type, find, label, model, staticOptions])

  useEffect(() => {
    const v = typeof value === 'object' ? value._id : value
    const errs = Errors(v as string, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, multiple, name, onChange, translatable, validations])

  const multipleOption = (id: string) => {
    let option = options.find(item => item._id === id)
    return (
      <div
        key={id}
        className="flex-grow-0 flex-shrink-0 px-1 mb-1 mr-1 text-xs border border-black rounded"
      >
        {option ? option.title : `Not found ${id}`}
        <span className="pl-1 cursor-pointer" onClick={() => removeItem(id)}>
          ✕
        </span>
      </div>
    )
  }

  return (
    <Field
      label={label}
      translatable={translatable}
      language={language}
      languages={languages}
      setLanguage={(v: string) => setLanguage(v)}
    >
      <div>
        <>
          <div className="relative">
            <div className="absolute right-0 py-1 mr-2">&#8595;</div>
            <select
              name={name}
              className={`w-full px-2 py-1 ${innerForm ? 'bg-white' : 'bg-grayLight'} ${showErrors && errors.length > 0 ? 'border border-error' : ''
                } rounded ${translatable && languages && Object.keys(languages)[0] === language
                  ? 'rounded-tl-none'
                  : ''
                } outline-none appearance-none`}
              placeholder={label}
              value={multiple ? '-1' : value[language] as string[]}
              onChange={onChangeSelect}
            >
              {options.map(option => (
                <option
                  key={option._id + option.title}
                  disabled={
                    option.disabled
                    || !!(multiple && value && value[language] && (value[language] as string[]).includes(option._id))
                  }
                  value={option._id}
                >
                  {option.title}
                </option>
              ))}
            </select>
          </div>
          {multiple && value && value[language] && Array.isArray(value[language]) && (value[language] as string[]).length > 0 && options.length > 1 && (
            <div className="flex flex-wrap mt-2">
              {(value[language] as string[])
                .map(id => id.length > 0 && multipleOption(id))}
            </div>
          )}
        </>
      </div>
    </Field>
  )
}


export default Select
