import React, { useState, useEffect } from 'react'
import Errors from './Errors'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttFieldType } from '../../types'
import Field from './Field'
import Button from '../Button'

interface AddressProps extends PttFieldType {}

const Address = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable = false,
  options: { label },
  validations,
  languages,
  innerForm,
  defaultLanguage,
}: AddressProps): React.ReactElement => {

  const defaultValue = getDefaultValue({ name, storedData, translatable })
  
  const [address, setAddress] = useState<string>('')
  const [language, setLanguage] = useState(defaultLanguage)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<string[]>([])


  const onSubmitAddress = async () => {    
    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBIIch_ba5C-f9eMbapzEhZbaQRqcMkR60`)
    const data = await response.json()

    if (data && data.results && data.results.length > 0) {
      value[language] = data.results[0]
      setValue({ ...value })
    } else {
      alert(`No address found for: ${address}`)
    }
  }
  
  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.value)
  }

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value, languages, name, onChange, storedData, translatable, validations])

  const formatAddress = (): React.ReactElement => {
    if (typeof value[language] !== 'undefined') {
      const add: {[key: string]: any} = value[language] as {[key: string]: any}
      return <span>{add.formatted_address}</span>
    }
    return <span>No address selected</span>
  }

  return (
    <div>   
      <Field
        label={label}
        translatable={translatable}
        language={language}
        languages={languages}
        setLanguage={(v: string) => setLanguage(v)}
      >
        <div className="flex">
          <input
            name={name}
            className={`w-full mr-4 rounded ${translatable && languages && Object.keys(languages)[0] === language
              ? 'rounded-tl-none'
              : ''
              } px-2 py-1 ${showErrors && errors.length > 0 ? 'border border-error' : ''
              } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
            type="text"
            placeholder={label}
            value={address}
            onChange={onChangeText}
          />
          <Button theme="dark" onClick={onSubmitAddress}>Search</Button>
        </div>
      </Field>
      <div className="p-4 mt-4 border border-solid">{formatAddress()}</div>
    </div>
  )
}

export default Address
