import { Link } from 'react-router-dom'

const Button = ({
  theme,
  children,
  loading,
  onClick,
  to,
}: {
  theme: 'clear' | 'dark' | 'outline-dark'
  children: React.ReactNode
  loading?: string | null
  onClick?: () => void
  to?: string
}) => {
  let extraClasses = ''
  switch (theme) {
    case 'clear':
      extraClasses =
        'border-secondary bg-secondary text-primary hover:bg-primary hover:text-secondary'
      break
    case 'dark':
      extraClasses =
        'border-primary bg-primary text-secondary hover:bg-secondary hover:text-primary'
      break
    case 'outline-dark':
      extraClasses = 'text-primary hover:bg-primary hover:text-secondary'
      break
    default:
      break
  }

  const className = `inline-block px-4 py-1 text-xs text-center border rounded cursor-pointer hover:border ${extraClasses}`

  if (to) {
    return (
      <Link className={className} to={to}>
        {children}
      </Link>
    )
  }

  return (
    <span className={className} onClick={onClick}>
      {children}
    </span>
  )
}

export default Button
