import dayjs from 'dayjs'

const printValue = ({ item, key }: { item: { [key: string]: unknown }, key: string }) => {
  if (key.includes('date')) {
    return dayjs(item[key] as string).format('DD/MM/YYYY')
  }
  if (key.includes('datetime')) {
    return dayjs(item[key] as string).format('DD/MM/YYYY HH:mm')
  }
  if (key.includes('time')) {
    return dayjs(item[key] as string).format('HH:mm')
  }
  if (typeof item[key] === 'boolean') {
    return item[key] ? 'Yes' : 'No'
  }
  return item[key]
}

export default printValue