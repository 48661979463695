import React from 'react'
import {
  Link
} from 'react-router-dom'

const ResetResult = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="p-4 text-center bg-white md:w-1/2">
        <div>
          <h2 className="mb-4 text-xl wght-semibold">Success</h2>
          <span className="wght-semibold">Your password has been changed.</span>
          <div className="flex items-end justify-center mt-4">
            <Link
                to={`/login`}
                className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
              >Return to login page &#8599;
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetResult