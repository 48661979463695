import React, { useState, useEffect } from 'react'
import getDefaultValue from '../../lib/getDefaultValue'
import { PttModelField, PttFieldType } from '../../types'
import Field from './Field'
interface ModelsProps extends PttFieldType {
  models: { [key: string]: PttModelField[] }
  options: {
    label: string
    multiple: boolean
    options: { [key: string]: string }
  }
  files: { [key: string]: string }
}

type LangObj = { [key: string]: string }

type Option = {
  enabled: boolean
  minLimitation?: number
  maxLimitation?: number
  errorMessage?: LangObj
}

const PlanInformation = ({
  showErrors,
  storedData,
  onChange,
  name,
  options: { label, options: staticOptions },
  find,
  defaultLanguage,
  languages,
}: ModelsProps) => {
  const [language, setLanguage] = useState(defaultLanguage)
  const [errors] = useState<string[]>()
  const defaultValue = getDefaultValue({
    name,
    storedData,
    translatable: false,
    shouldReturnDefault: true,
    defaultValue: {},
  })
  let [optionsData, setOptionsData] = useState<{ [key: string]: Option }>(
    defaultValue.default as { [key: string]: Option }
  )
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    onChange(name, value, errors)
  }, [value, errors, name, onChange])

  useEffect(() => {
    value[language] = optionsData
    setValue({ ...value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsData])

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLElement>) => {
    defaultCreation(e.target.getAttribute('id') as string)
    optionsData[e.target.getAttribute('id') as string].enabled =
      !optionsData[e.target.getAttribute('id') as string].enabled
    setOptionsData({ ...optionsData })
  }

  const defaultCreation = (id: string) => {
    if (typeof optionsData[id] === 'undefined') {
      optionsData[id] = {
        enabled: false,
        minLimitation: 0,
        maxLimitation: 0,
        errorMessage: {},
      }
    }
  }

  const onChangeMinLimitation = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultCreation(e.target.getAttribute('id') as string)
    optionsData[e.target.getAttribute('id') as string].minLimitation = Number(
      e.target.value
    )
    setOptionsData({ ...optionsData })
  }

  const onChangeMaxLimitation = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultCreation(e.target.getAttribute('id') as string)
    optionsData[e.target.getAttribute('id') as string].maxLimitation = Number(
      e.target.value
    )
    setOptionsData({ ...optionsData })
  }

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    defaultCreation(e.target.getAttribute('id') as string)
    if (
      typeof optionsData[e.target.getAttribute('id') as string].errorMessage ===
      'undefined'
    ) {
      optionsData[e.target.getAttribute('id') as string].errorMessage = {}
    }
    if (
      optionsData[e.target.getAttribute('id') as string].errorMessage as LangObj
    ) {
      optionsData[e.target.getAttribute('id') as string].errorMessage![
        language
      ] = e.target.value
    }
    setOptionsData({ ...optionsData })
  }

  return (
    <div className='p-2 border border-2 rounded md:py-2 md:px-2'>
      <div className='flex items-center justify-between mt-1 wght-semibold'>
        <span>{label}</span>
      </div>
      <div>
        <table className='w-full'>
          <tbody>
            <tr className='uppercase border-b table-auto border-grayLight bg-grayLight text-sm'>
              <td className='px-2 py-2'>Option</td>
              <td className='px-2 py-2'>Enabled</td>
              <td className='px-2 py-2'>Min Limit (meter)</td>
              <td className='px-2 py-2'>Max Limit (meter)</td>
              <td className='px-2 py-2'>Error message</td>
            </tr>
            {Object.keys(staticOptions).map(option => (
              <tr className='border-b table-auto border-grayLight'>
                <td className='px-2 py-2'>{staticOptions[option]}</td>
                <td className='px-2 py-2'>
                  <input
                    type='checkbox'
                    checked={optionsData[option]?.enabled}
                    id={option}
                    onChange={onChangeCheckbox}
                  />
                </td>
                <td className='px-2 py-2'>
                  <input
                    className='w-16 rounded bg-grayLight p-1'
                    type='number'
                    value={optionsData[option]?.minLimitation}
                    id={option}
                    onChange={onChangeMinLimitation}
                  />
                </td>
                <td className='px-2 py-2'>
                  <input
                    className='w-16 rounded bg-grayLight p-1'
                    type='number'
                    value={optionsData[option]?.maxLimitation}
                    id={option}
                    onChange={onChangeMaxLimitation}
                  />
                </td>
                <td>
                  <Field
                    label={'Error message'}
                    translatable={true}
                    language={language}
                    languages={languages}
                    setLanguage={(v: string) => setLanguage(v)}
                  >
                    <input
                      name={name}
                      className={`w-full rounded ${
                        true &&
                        languages &&
                        Object.keys(languages)[0] === language
                          ? 'rounded-tl-none'
                          : ''
                      } px-2 py-1`}
                      type='text'
                      placeholder={'Error message'}
                      value={`${
                        optionsData[option] &&
                        optionsData[option].errorMessage &&
                        optionsData[option].errorMessage![language]
                          ? optionsData[option].errorMessage![language]
                          : ''
                      }`}
                      id={option}
                      onChange={onChangeText}
                    />
                  </Field>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PlanInformation
