
function getDefaultValue({name, storedData, translatable, shouldReturnDefault = false, defaultValue}: {name: string, storedData: {[key: string]: unknown}, translatable: boolean, shouldReturnDefault? : boolean, defaultValue?: any}) {

  let value
  if (typeof storedData[name] !== 'undefined') {
    if (translatable) {
      value = storedData[name] as {[key: string]: unknown}
    } else {
      value = {
        default: storedData[name]
      }
    }
  } else {
    if (translatable) {
      value = {}
    } else {
      value = {
        default: shouldReturnDefault ? defaultValue : undefined
      }
    }
  }

  return value 
}

export default getDefaultValue