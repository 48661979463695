import React, { useEffect, useState } from 'react'
import {
  Route,
  NavLink,
  useNavigate,
  useLocation,
  Routes,
} from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage'
import Edit from './Edit'
import List from './List'
import Dashboard from './Dashboard'
import TopBar from '../components/TopBar'
import Container from '../components/Container'
import { GlobalConfig } from '../store'
import { AppConfig, AppConfigMenuItemType } from '../types'
import Button from '../components/Button'

const Main = ({ tokenIsValid }: { tokenIsValid: Boolean | null }) => {

  const navigate = useNavigate()
  const location = useLocation()
  const [appConfig, setAppConfig] = useState<AppConfig>()
  const [user, setUser] = useLocalStorage('user', null)
  const [token, setToken] = useLocalStorage('token', null)

  useEffect(() => {
    if (appConfig) return

    fetch(`${process.env.REACT_APP_API_URL}config`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setAppConfig(response.body.config)
        } else {
          if (response.status === 403) {
            setToken(null)
            navigate('/login')
          }
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const logout = () => {
    setToken(null)
    setUser(null)
    navigate('/login', { replace: true })
  }

  const deploy = async () => {
    if (typeof appConfig?.deploy?.url !== 'undefined' && window.confirm('##Deploy##\n\nYou should only deploy once everything is correct.')) {
      await fetch(appConfig?.deploy?.url, {
        method: 'POST',
      })
      alert('It might take a while to publish the changes...')
      window.open(appConfig.deploy.afterDeployUrl)
    }
  }

  let username = 'Anonymous'
  if (user) {
    username = user.name
  }
  
  const isMenuItemActive = (item: AppConfigMenuItemType) => {
    let active = false
    if (typeof item.items !== 'undefined') {
      item?.items.forEach(subitem => {
        const section = location.pathname.split('/')[3]
        if (!active && section === subitem.model) {
          active = true
        }
      })
    }
    if (!active) {
      return location.pathname.includes(item.model)
    }
    return active
  }

  const Menu = () => (
    <>
      <div className="md:w-2/12"></div>
      <nav className="px-4 md:fixed md:h-screen md:top-0 md:left-0 md:bottom-0 md:px-0 md:w-2/12 md:flex md:flex-col bg-primary"> 
        <div className="pb-12 text-secondary md:flex-1 md:overflow-scroll">
          <TopBar>
            <h1 className="hidden px-4 md:block wght-bold">
              <NavLink to="/">{appConfig && appConfig.app.title}</NavLink>
            </h1>
          </TopBar>
          {appConfig &&
            <ul className="flex md:block">
              {appConfig.menu.map((item, index) => (
                <li key={index} className="mr-4 md:border-b md:border-tertiary md:mr-0">
                  <NavLink className={`border-solid md:block md:p-4 hover:wght-semibold ${isMenuItemActive(item) ? 'text-primary wght-semibold md:bg-white hover:text-primary' : 'hover:text-secondary'}`} to={item.path || `/admin/list/${item.model}`}>
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          }
          {appConfig && appConfig.deploy && <div className="items-center hidden px-4 py-4 md:block wght-semibold ">
            <Button theme="clear" onClick={() => deploy()}>Publish</Button>
          </div>}
        </div>
        <div className="items-center hidden p-4 text-xs bg-tertiary md:h-12 md:flex md:absolute md:bottom-0 md:left-0 md:right-0 text-secondary">
          <p>👋 Hi <span className="capitalize">{username}</span>. <span onClick={logout} className="cursor-pointer hover:wght-semibold">Log out</span></p>
        </div>
      </nav>
    </>
  )

  const TopMenu = () => (
    <>
      {appConfig && appConfig.menu.map(item => {
        if (isMenuItemActive(item)) {
          return (
            <Container key={item.model}>
              <ul className="flex text-sm">
                {
                  item.items && item.items.map((subitem, index) => (
                    <li key={index} className="mr-4">
                      <NavLink className={({ isActive }) => `hover:text-primary hover:wght-semibold ${isActive || pathNameArr.includes(subitem.model) ? 'wght-semibold' : ''}`} to={subitem.path || `/admin/list/${subitem.model}/`}>
                        {subitem.title}
                      </NavLink>
                    </li>
                  ))
                }
              </ul>
            </Container>
          )
        }
        return null
      })}
    </>
  )

  const [pathNameArr, setPathNameArr] = useState(location.pathname.split('/'))

  useEffect(() => {
    setPathNameArr(location.pathname.split('/'))
  }, [location.pathname])

  return (
    <div className="flex flex-col min-h-screen">
      <div className="md:flex">
        <Menu />
        <div className="md:w-10/12">
          <TopBar>
            <TopMenu />
          </TopBar>
          <GlobalConfig config={appConfig}>
            {appConfig && <Container>
              <Routes>
                <Route index element={<Dashboard />} />
                <Route path="/edit">
                  <Route path=":name/:id" element={<Edit />} />
                  <Route path=":name" element={<Edit />} />
                </Route>
                <Route path="/list">
                  <Route path=":name" element={<List />} />
                </Route>
              </Routes>
            </Container>}
          </GlobalConfig>
          <div className="p-4 mt-4 text-xs md:hidden">
            <p>👋 Hi <span className="capitalize">{username}</span>. Want to <span onClick={logout} className="cursor-pointer wght-bold hover:wght-semibold">log out</span>?</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Main
